import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MascotDataHeader } from "../components/MascotDataHeader";
import useDrivePicker from "react-google-drive-picker";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";
import useQuery from "../hooks/useQuery";
import { ListDataFile } from "../components/ListDataFile";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import IconGoogleDrive from "../images/icons/google-drive.svg";
import GoogleDrivePermission from "../images/googledrivepermission.png";

export default function EditMascotDataGoogleDrive() {
  const { mascotId } = useParams();
  const { currentUser, currentOrganization, uploadInfo } = useAuth();
  const { embeddingTask, addTask, taskList, isUpdating } = useTasks();
  const { mascot, updateDataLastModified } = useEditMascot();
  const [openPicker] = useDrivePicker();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const mimeLabelMap = {
    "application/vnd.google-apps.document": "Google Docs",
    "application/vnd.google-apps.spreadsheet": "Google Sheets",
    "application/vnd.google-apps.presentation": "Google Slides",
  };

  const browse = async () => {
    const result = await servicesClient.getGoogleDriveToken(currentOrganization._id);
    const token = result.ok && result.data.accessToken;

    if (!token) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "gdrive").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else {
      openPicker({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
        viewId: "DOCS",
        token: token,
        showUploadView: false,
        showUploadFolders: false,
        supportDrives: true,
        multiselect: true,
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        callbackFunction: handlePickerSelection,
      });
    }
  };

  const authorizeUser = async () => {
    const result = await servicesClient.authorizeGoogleDrive(currentOrganization._id, window.location.href);
    if (result.ok) {
      window.location.href = result.data.authUrl;
    }
  };

  const handlePickerSelection = async (data) => {
    if (data.action === "picked" && data.docs) {
      const filteredDocs = data.docs.filter((file) => {
        if (
          mascot.data?.uploads.filter((doc) => doc.type === "gdrive").some((u) => u.googleDriveId === file.id) ||
          taskList
            .filter((doc) => doc.type === "addGoogleDriveTask" && doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        if (uploadInfo.maxContentLength && uploadInfo.maxContentLength < file.sizeBytes) {
          const maxSizeMb = (uploadInfo.maxContentLength / (1024 * 1024)).toFixed(2);
          toast.error(() => (
            <>
              <b>{file.name}</b>: File size limit exceeded ({maxSizeMb} MB).
            </>
          ));
          return false;
        }
        return true;
      });
      const result = await servicesClient.dataAddGoogleDrive(mascotId, filteredDocs, currentUser._id);
      if (!result.ok || result.data.error) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => (
          <>
            <b>{filteredDocs[0].name}</b>: {String(result.originalError)}.
          </>
        ));
        return;
      }
      result.data?.forEach((item) => addTask(item));
    }
  };

  const checkUpdates = async () => {
    setShowUpdateLoader(true);
    const result = await servicesClient.dataCheckUpdatesGoogleDrive(mascotId);
    setShowUpdateLoader(false);
    if (result.ok) {
      for (const [key, value] of Object.entries(result.data)) {
        value === null &&
          toast.error(() => (
            <>
              <b>Cannot update file:</b> "{mascot.data?.uploads.find((u) => u._id === key)?.name}" was deleted from Google Drive.
            </>
          ));
      }
      updateDataLastModified(result.data);
    }
  };

  const handleRefreshDoc = async (upload) => {
    let pendingUpdateTask = isUpdating(upload);
    if (!pendingUpdateTask) {
      const result = await servicesClient.dataUpdateGoogleDrive(mascotId, upload._id, currentUser._id);
      result.ok &&
        addTask({
          ...result.data,
          meta: { filename: upload.name, mimeType: upload.mimeType, googleDriveId: result.data.meta.googleDriveId },
        });
    } else {
      addTask({
        status: "failed",
        type: "grive",
        meta: {
          error: "Update already in progress",
          filename: pendingUpdateTask.meta.filename,
          mimeType: pendingUpdateTask.meta.mimeType,
        },
      });
    }
  };

  const getFileAction = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && (
        <i
          className="icon-reload"
          onClick={() => {
            handleRefreshDoc(doc);
          }}
        ></i>
      )}
    </>
  );

  const getFileActionPermanent = (doc) => (
    <>
      {!embeddingTask &&
        doc.lastModified > doc.lastUpdate &&
        doc.googleDriveAccount === currentOrganization.googleDriveConnected && (
          <span className="meta small warning">This file has changes, please update</span>
        )}
      {!embeddingTask && doc.googleDriveAccount !== currentOrganization.googleDriveConnected && (
        <span className="meta small">Not synced (account changed)</span>
      )}
    </>
  );

  const docsToUpdate = (docs) => {
    return docs.filter(
      (doc) =>
        doc.type === "gdrive" &&
        doc.lastModified > doc.lastUpdate &&
        doc.googleDriveAccount === currentOrganization.googleDriveConnected
    );
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane content-wrapper full-width">
        {!currentOrganization.googleDriveConnected ? (
          <>
            <h3>
              <img src={IconGoogleDrive} alt={IconGoogleDrive}></img> Connect Google Drive
            </h3>

            <p>Connect your Google account to sync files from Drive to your mascot.</p>
          </>
        ) : (
          <>
            <h3>
              <img src={IconGoogleDrive} alt={IconGoogleDrive}></img> Google Drive Files
            </h3>
          </>
        )}

        {query.has("error") && <p className="meta warning">We were not able to connect you to Drive. Please try again.</p>}

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "gdrive").length > 0 ? (
          <ListDataFile
            title="Uploaded Files"
            searchLabel="Search synced files"
            getFileAction={getFileAction}
            getFileActionPermanent={getFileActionPermanent}
            disabled={!!embeddingTask}
            handleRefreshDoc={handleRefreshDoc}
            docsToUpdate={docsToUpdate}
            buttonBar={
              <>
                <button className="small" onClick={browse} disabled={embeddingTask}>
                  {currentOrganization.googleDriveConnected ? "Browse Files" : "Connect Account"}
                </button>

                {currentOrganization.googleDriveConnected && (
                  <button className="small" disabled={showUpdateLoader} onClick={checkUpdates}>
                    {showUpdateLoader && <Loader classNames="small"></Loader>}
                    <span>Check For Updates</span>
                  </button>
                )}
              </>
            }
            files={mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "gdrive").reverse()}
            mimeLabelMap={mimeLabelMap}
          ></ListDataFile>
        ) : (
          <button className="small" onClick={browse} disabled={embeddingTask}>
            {currentOrganization.googleDriveConnected ? "Browse Files" : "Connect Account"}
          </button>
        )}

        <Modal
          title="Connect to Google"
          size="small"
          isOpen={showConfirmModal}
          close={() => setShowConfirmModal(false)}
          action={
            <>
              <button className="action" onClick={authorizeUser}>
                Continue
              </button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </>
          }
        >
          <p>Existing Google Drive documents will not sync if connecting with a different Google account.</p>
        </Modal>
        <Modal
          title={
            query.get("error") === "ScopeMissingError"
              ? "Connect to Google"
              : `Authentication Error ${query.get("error")}`
          }
          isOpen={query.has("error")}
          close={() => history.push(location.pathname)}
          action={
            <button className="action" onClick={() => history.push(location.pathname)}>
              Got It
            </button>
          }
        >
          {query.get("error") === "ScopeMissingError" ?
            <>
              <p>There was an issue connecting your Google account.</p>
              <p>
                Please try connecting again. When prompted, select the checkbox next to "See and download all your
                Google Drive Files":
              </p>
              <img src={GoogleDrivePermission} alt="Google Drive Permission" style={{display: "block", width: "auto", height: "72px", margin: "20px 15px 30px 0", paddingLeft: "15px", borderLeft: "2px solid rgba(0,0,0,0.2)"}}></img>
            </>
            :
            <p>
              Authentication failed with the following error: <br />
              <code>{query.get("error_description")}</code>
            </p>
          }
        </Modal>
      </div>
    </div>
  );
}
