import React, { useState, useEffect, useContext, createContext } from "react";
import { client } from "../services/api";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "./useAuth";
import { useTasks } from "./useTasks";
import { publish, subscribe, unsubscribe } from "../services/events";
import { useMascot } from "./useMascot";

const editMascotContext = createContext();

function useProvideEditMascot() {
  const { currentUser } = useAuth();
  const { setEmbeddingTask, embeddingTask, setMinimized, setEmbeddingProgress, setEmbeddingTaskLastResult } = useTasks();
  const { mascotId } = useParams();
  const { mascot, setMascot } = useMascot();
  const [mascotDraft, setMascotDraft] = useState({});
  const [lastSavedDate, setLastSavedDate] = useState();

  useEffect(() => {
    subscribe("onTaskCompleted", onTaskCompleted);
    return () => {
      unsubscribe("onTaskCompleted", onTaskCompleted);
    };
    // eslint-disable-next-line
  }, []);

  const onTaskCompleted = (updatedMascot) => {
    if (updatedMascot.detail?._id === mascotId) {
      setMascot((mascot) => {
        mascot.data = updatedMascot.detail.data;
        return mascot;
      });
    }
  };

  useEffect(() => {
    if (mascot && mascot._id) {
      setMascotDraft(mascot.draft && mascot.draft.editDate ? mascot.draft : mascot);
    }
  }, [mascot]);

  const updateDataLastModified = (updateObj) => {
    const updatedUploads = mascot.data?.uploads.map((upload) =>
      updateObj[upload._id] ? { ...upload, lastModified: updateObj[upload._id] } : upload
    );
    setMascot({ ...mascot, data: { ...mascot.data, uploads: updatedUploads } });
  };

  const saveDraft = (mascotDraft) => {
    mascotDraft.editDate = new Date();
    setMascot({ ...mascot, ...{ draft: mascotDraft } });
    updateMascotDraft(mascotDraft);
  };

  const hasToBePublished = () => {
    return (
      mascotDraft.editDate || (mascot.data && mascot.data.stale && embeddingTask === null) || (mascot._id && !mascot.published)
    );
  };

  const setStale = (stale) => {
    mascot.data.stale = stale;
    setMascot({ ...mascot });
  };

  const updateMascotDraft = useDebouncedCallback(async (mascotDraft) => {
    await client.updateMascot({ _id: mascot._id, draft: mascotDraft });
    setLastSavedDate(new Date());
  }, 500);

  const publishDraft = async () => {
    if (!mascotDraft.name || !mascotDraft.description) {
      toast.error("Mascot name and description is required");
      return;
    }

    const newMascot = { ...mascot, ...mascotDraft, published: true, draft: {} };
    const res = await client.updateMascot(newMascot);

    if (!res.ok) {
      toast.error("Error updating mascot");
      console.error(res.originalError);
    }

    newMascot.editDate = null;
    setMascot({ ...newMascot });

    if (newMascot.data.stale && !embeddingTask) {
      const result = await servicesClient.embeddingGenerate(newMascot._id, { userId: currentUser._id });
      if (result.ok && result.data) {
        setEmbeddingProgress({
          task: "",
          step: 0,
          substep: 0,
          progress: 0,
        });
        setEmbeddingTaskLastResult();
        setEmbeddingTask(result.data);
        setMinimized(false);
      } else {
        toast.error("Server error!");
      }
    }
  };

  const unpublish = async () => {
    mascot.published = false;
    const res = await client.updateMascot(mascot);
    if (!res.ok) {
      toast.error("Error updating mascot");
      console.error(res.originalError);
    } else {
      setMascot({ ...mascot });
    }
  };

  const handleDeleteUpload = async (uploadId) => {
    let res = await servicesClient.dataDelete(mascotId, uploadId);

    if (res.ok) {
      mascot.data.uploads = mascot.data?.uploads.filter((u) => u._id !== uploadId);
      mascot.data.stale = true;
      setMascot({ ...mascot });
      publish("removedDoc");
    }
  };

  const handleRefreshingUpload = async (uploadId) => {
    mascot.data.uploads = mascot.data?.uploads.map((u) => {
      if (u._id === uploadId) {
        u.refreshing = true;
      }
      return u;
    });
    setMascot({ ...mascot });
  };

  return {
    mascot,
    mascotDraft,
    lastSavedDate,
    saveDraft,
    publishDraft,
    hasToBePublished,
    unpublish,
    handleDeleteUpload,
    handleRefreshingUpload,
    setStale,
    updateDataLastModified,
  };
}

export function ProvideEditMascot({ children }) {
  const editMascot = useProvideEditMascot();
  return <editMascotContext.Provider value={editMascot}>{children}</editMascotContext.Provider>;
}

export const useEditMascot = () => {
  return useContext(editMascotContext);
};
